import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonFab, IonFabButton, IonIcon, useIonActionSheet, useIonModal, useIonToast } from '@ionic/react';
import { add } from 'ionicons/icons';
import { loader } from 'graphql.macro';
import { stringToByteArray, toBase64 } from '@ssg/common/Helpers/inputFileHelper';
import BasePage from 'Components/Layout/BasePage';
import {
	GetMobileV2DrivingSlipFile,
	GetDrivingSlipFiles,
	GetDrivingSlipFilesVariables,
	GetDrivingSlipFiles_drivingSlipFiles,
	DeleteDrivingSlipFile,
	DeleteDrivingSlipFileVariables,
	FileInput,
	FileMetadataField,
	GetMobileV2DrivingSlipFileVariables,
	GetCaseFiles,
	GetCaseFilesVariables,
} from 'GQL';
import { getFileName, useOfflineHandlingMutation, useOfflineHandlingQuery, usePhotoChooser } from 'Hooks';
import { FileType, getFileTypeFromUrl } from '@ssg/common/Helpers/fileTypeHelper';
import DrivingSlipFilesListElementLoading from './DrivingSlipFilesListElementLoading';
import DrivingSlipFilesDocumentList from './DrivingSlipFilesDocumentList';
import DrivingSlipFilesImageList from './DrivingSlipFilesImageList';
import DrivingSlipFilesVideoList from './DrivingSlipFilesVideoList';
import VideoCaptureModal, { Props as VideoCaptureModalProps } from 'Components/VideoCaptureModal';
import { UserPhoto, useSinglePhotoCapture } from 'Hooks/usePhotoCapture';
import { getPath, UserVideo } from 'Hooks/useVideoCapture';
import DocumentViewerModal, { Props as DocumentViewerModalProps } from 'Components/DocumentViewerModal';
import { CameraSource } from '@capacitor/camera';
import AddCommentsToFilesModal, { AddCommentsToFilesModalREST, AddingFilesType, Props as ChosenImagesModalProps, PropsRest } from 'Components/AddCommentsToFilesModal';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { ApiMutationKey, useUploadDrivingSlipFile } from 'Store';
import UserContext from 'UserContext';
import { useObjectUrls } from 'Hooks/useObjectUrls';
import { UploadDrivingSlipFileProps } from '../Questionnaire/DrivingSlipQuestionnaireOverview';
import { useUploadDrivingSlipFilesRest } from 'Hooks/useUploadDrivingSlipFilesRest';

const GET_DRIVING_SLIP_FILE = loader('src/GQL/Files/GetMobileV2DrivingSlipFile.gql');
const GET_DRIVING_SLIP_FILES = loader('src/GQL/Files/GetDrivingSlipFiles.gql');
const DELETE_FILE = loader('src/GQL/Files/DeleteDrivingSlipFile.gql');
const GET_CASE_FILES = loader('src/GQL/Files/GetCaseFiles.gql');

interface FileGroups {
	images: GetDrivingSlipFiles_drivingSlipFiles[];
	videos: GetDrivingSlipFiles_drivingSlipFiles[];
	documents: GetDrivingSlipFiles_drivingSlipFiles[];
}

export interface FileToDownload {
	fileName: string;
	extension: string;
	folder: string;
	downloadType?: 'view' | 'download';
}

interface Props {
	caseNo: string;
	series: string;
}

interface PhotoUploadProps {
	files: UploadDrivingSlipFileProps[];
	comments?: string;
}

const getFileModifiedDateTime = (file: GetDrivingSlipFiles_drivingSlipFiles): number => {
	const modified = file.metadata.find(m => m.key === 'Modified')?.value;

	if (modified) {
		const date = new Date(modified);
		return date.getTime();
	}

	return 0;
};

const DrivingSlipFilesOverview: React.FC<Props> = ({ caseNo, series }) => {
	const { t } = useTranslation();
	const [presentAddFileMenu] = useIonActionSheet();
	const { user } = React.useContext(UserContext);
	const getObjectUrl = useObjectUrls();

	const mobileImageComment = useFlag(FeatureFlagEnums.MOBILE_IMAGE_COMMENT);
	const fileUploadMobileV2 = useFlag(FeatureFlagEnums.FILE_UPLOAD_MOBILE_V2);

	const [uploadingFileType, setUploadingFileType] = useState<FileType | undefined>();
	const [fileToDownload, setFileToDownload] = useState<FileToDownload>({
		fileName: '',
		extension: '',
		folder: series,
	});

	// const inputFileRef = useRef<HTMLInputElement | null>(null);

	const { data: dsFileToDownload, loading: dsFileToDownloadLoading } = useOfflineHandlingQuery<GetMobileV2DrivingSlipFile, GetMobileV2DrivingSlipFileVariables>(GET_DRIVING_SLIP_FILE, {
		variables: {
			fileName: fileToDownload.fileName,
			caseNo: caseNo,
			drivingSlipId: fileToDownload.folder,
		},
		skip: fileToDownload.fileName === '',
	});

	useEffect(() => {
		if (dsFileToDownload?.drivingSlipFile && fileToDownload.downloadType === 'download') {
			const url = window.URL.createObjectURL(new Blob([new Uint8Array(dsFileToDownload?.drivingSlipFile.fileData)]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${dsFileToDownload?.drivingSlipFile.filename}`);
			// 3. Append to html page
			document.body.appendChild(link);
			// 4. Force download
			link.click();
			// 5. Clean up and remove the link
			link.parentNode && link.parentNode.removeChild(link);
		}
	}, [dsFileToDownload, fileToDownload.downloadType]);

	const {
		data: dsFiles,
		loading: loadingDsFiles,
		refetch: refetchDsFiles,
	} = useOfflineHandlingQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>(
		GET_DRIVING_SLIP_FILES,
		{
			variables: {
				caseNo: caseNo,
				drivingSlipId: series,
			},
		},
		false,
		'here',
	);

	const [deleteDsFile, { loading: loadingDeleteFile }] = useOfflineHandlingMutation<DeleteDrivingSlipFile, DeleteDrivingSlipFileVariables>(DELETE_FILE);
	const [uploadDsFile, { loading: loadingUploadFile }] = useUploadDrivingSlipFile();

	const [presentDocumentViewer, dismissDocumentViewer] = useIonModal(DocumentViewerModal, {
		file: dsFileToDownload?.drivingSlipFile
			? {
				name: dsFileToDownload.drivingSlipFile.filename,
				extension: fileToDownload.extension,
				data: dsFileToDownload.drivingSlipFile.fileData,
				base64Data: dsFileToDownload.drivingSlipFile.fileDataBase64,
			}
			: undefined,
		onDismiss: () => dismissDocumentViewer(),
	} as DocumentViewerModalProps);

	const fileGroups: FileGroups = useMemo(() => {
		const files = dsFiles?.drivingSlipFiles ?? [];

		const images: GetDrivingSlipFiles_drivingSlipFiles[] = [];
		const videos: GetDrivingSlipFiles_drivingSlipFiles[] = [];
		const documents: GetDrivingSlipFiles_drivingSlipFiles[] = [];

		for (const file of files) {
			const type = getFileTypeFromUrl(file.url);

			switch (type) {
				case FileType.Image:
					images.push(file);
					break;
				case FileType.Video:
					videos.push(file);
					break;
				default:
					documents.push(file);
					break;
			}
		}

		const sortByDateDesc = (a: GetDrivingSlipFiles_drivingSlipFiles, b: GetDrivingSlipFiles_drivingSlipFiles) => getFileModifiedDateTime(b) - getFileModifiedDateTime(a);

		return {
			images: images.sort(sortByDateDesc),
			videos: videos.sort(sortByDateDesc),
			documents: documents.sort(sortByDateDesc),
		};
	}, [dsFiles]);

	const onDelete = async (file: GetDrivingSlipFiles_drivingSlipFiles) => {
		const fileName = file.name;
		await deleteDsFile({
			variables: {
				caseNo: caseNo,
				drivingSlipId: series,
				fileName: fileName,
			},
			update: (cache, { data: cacheData }): void => {
				if (typeof cacheData === 'undefined' || cacheData === null) {
					return;
				}

				const cachedRequest = cache.readQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
					query: GET_DRIVING_SLIP_FILES,
					variables: {
						caseNo: caseNo,
						drivingSlipId: series,
					},
				});

				if (cachedRequest === null || cachedRequest.drivingSlipFiles === null) {
					return;
				}

				cache.writeQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
					query: GET_DRIVING_SLIP_FILES,
					variables: {
						caseNo: caseNo,
						drivingSlipId: series,
					},
					data: {
						drivingSlipFiles: cachedRequest.drivingSlipFiles.filter(doc => doc.name !== fileName),
					},
				});
			},
		});
	};

	const onUpload = async (fileInputs: (FileInput & { blob: string })[], comments?: string) => {
		// It is possible to upload multiple file types at once.
		// Therefore enumerate the files and create a Bitwise file type that represents the given permutation.
		let fileTypeToSet = FileType.None;
		fileInputs.forEach(fileInput => {
			fileTypeToSet |= getFileTypeFromUrl(fileInput.filename);
			fileInput.fileMetadata = comments ? [{ key: FileMetadataField.Comments, value: comments }] : undefined;
		});

		setUploadingFileType(fileTypeToSet);
		// const variables = {
		// 	caseNo: caseNo,
		// 	drivingSlipId: series,
		// 	files: fileInputs.map(f => ({
		// 		filename: f.filename,
		// 		fileData: f.fileData,
		// 		fileMetadata: f.fileMetadata,
		// 	})),
		// };

		// const mappedFiles = fileInputs.map(f => ({
		// 	filename: f.filename,
		// 	fileData: f.fileData,
		// 	fileMetadata: f.fileMetadata,
		// }));

		const newObj: GetDrivingSlipFiles_drivingSlipFiles[] = fileInputs.map<GetDrivingSlipFiles_drivingSlipFiles>((f, i) => {
			const fileSplit = f.filename.split('.');
			const extension = fileSplit[fileSplit.length - 1];
			const date = new Date().toISOString();
			const metaDataArr = [...(f.fileMetadata ?? []), { key: 'UploadedBy', value: user?.id ?? '' }, { key: 'Modified', value: date }];
			return {
				comments: comments ?? null,
				created: date,
				extension: extension,
				fileId: '234324' + i,
				groupingKey: '',
				metadata: metaDataArr,
				name: f.filename,
				thumbnail: f.blob,
				url: f.blob + '.' + extension,
				__typename: 'ESDHFile',
			};
		});

		for (let index = 0; index < fileInputs.length; index++) {
			const { blob, ...file } = fileInputs[index];

			const variables = {
				caseNo: caseNo,
				drivingSlipId: series,
				files: [file],
			};

			await uploadDsFile({
				variables: variables,
				queueOfflineMutationOptions: {
					key: ApiMutationKey.DrivingSlip_UploadFile,
					removePreviousEntry: false,
				},
				forceQueue: true,
				onOptimisticResponseOverride: async cache => {
					if (index !== 0) return;
					// const newObj: GetDrivingSlipFiles_drivingSlipFiles[] = fileInputs.map<GetDrivingSlipFiles_drivingSlipFiles>((f, i) => {
					// 	const fileSplit = f.filename.split('.');
					// 	const extension = fileSplit[fileSplit.length - 1];
					// 	const date = new Date().toISOString();
					// 	const metaDataArr = [...(f.fileMetadata ?? []), { key: 'UploadedBy', value: user?.id ?? '' }, { key: 'Modified', value: date }];
					// 	return {
					// 		comments: comments ?? null,
					// 		created: date,
					// 		extension: extension,
					// 		fileId: '234324' + i,
					// 		groupingKey: '',
					// 		metadata: metaDataArr,
					// 		name: f.filename,
					// 		thumbnail: f.blob,
					// 		url: f.blob + '.' + extension,
					// 		__typename: 'ESDHFile',
					// 	};
					// });

					const cachedRequest = cache.readQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
						query: GET_DRIVING_SLIP_FILES,
						variables: {
							caseNo: caseNo,
							drivingSlipId: series,
						},
					});
					cache.writeQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
						query: GET_DRIVING_SLIP_FILES,
						variables: {
							caseNo: caseNo,
							drivingSlipId: series,
						},
						data: {
							drivingSlipFiles: [...newObj, ...(cachedRequest?.drivingSlipFiles ?? [])],
						},
					});

					const cachedRequestCaseFiles = cache.readQuery<GetCaseFiles, GetCaseFilesVariables>({
						query: GET_CASE_FILES,
						variables: {
							caseErpNo: caseNo,
							folder: caseNo,
						},
					});
					cache.writeQuery<GetCaseFiles, GetCaseFilesVariables>({
						query: GET_CASE_FILES,
						variables: {
							caseErpNo: caseNo,
							folder: caseNo,
						},
						data: {
							caseFiles: [...newObj, ...(cachedRequestCaseFiles?.caseFiles ?? [])],
						},
					});
				},
			});
		}
	};

	const onPhotosUpload = async ({ files, comments }: PhotoUploadProps) => {
		const newObj: GetDrivingSlipFiles_drivingSlipFiles[] = files.map<GetDrivingSlipFiles_drivingSlipFiles>((f, i) => {
			const fileSplit = f.filename.split('.');
			const extension = fileSplit[fileSplit.length - 1];
			const date = new Date().toISOString();
			const metaDataArr = [
				{ key: 'UploadedBy', value: user?.id ?? '' },
				{ key: 'Modified', value: date },
			];
			return {
				comments: comments ?? null,
				created: date,
				extension: extension,
				fileId: '234324' + i,
				groupingKey: '',
				metadata: metaDataArr,
				name: f.filename,
				thumbnail: f.blob ?? '',
				url: f.blob + '.' + extension,
				__typename: 'ESDHFile',
			};
		});
		//rebuild
		for (let index = 0; index < files.length; index++) {
			const { blob, ...file } = files[index];
			const fileMetadata: any[] = [];
			if (comments) {
				fileMetadata.push({
					key: FileMetadataField.Comments,
					value: comments,
				});
			}
			const uploadFile: FileInput = {
				fileData: file.fileData,
				filename: file.filename,
				fileMetadata: fileMetadata,
			};
			const variables = {
				caseNo: caseNo,
				drivingSlipId: series,
				files: [uploadFile],
			};
			await uploadDsFile({
				variables: variables,
				queueOfflineMutationOptions: {
					key: ApiMutationKey.DrivingSlip_UploadFile,
					removePreviousEntry: false,
				},
				forceQueue: true,
				onOptimisticResponseOverride: async cache => {
					if (index !== 0) return;
					// const newObj: GetDrivingSlipFiles_drivingSlipFiles[] = fileInputs.map<GetDrivingSlipFiles_drivingSlipFiles>((f, i) => {
					// 	const fileSplit = f.filename.split('.');
					// 	const extension = fileSplit[fileSplit.length - 1];
					// 	const date = new Date().toISOString();
					// 	const metaDataArr = [...(f.fileMetadata ?? []), { key: 'UploadedBy', value: user?.id ?? '' }, { key: 'Modified', value: date }];
					// 	return {
					// 		comments: comments ?? null,
					// 		created: date,
					// 		extension: extension,
					// 		fileId: '234324' + i,
					// 		groupingKey: '',
					// 		metadata: metaDataArr,
					// 		name: f.filename,
					// 		thumbnail: f.blob,
					// 		url: f.blob + '.' + extension,
					// 		__typename: 'ESDHFile',
					// 	};
					// });

					const cachedRequest = cache.readQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
						query: GET_DRIVING_SLIP_FILES,
						variables: {
							caseNo: caseNo,
							drivingSlipId: series,
						},
					});
					cache.writeQuery<GetDrivingSlipFiles, GetDrivingSlipFilesVariables>({
						query: GET_DRIVING_SLIP_FILES,
						variables: {
							caseNo: caseNo,
							drivingSlipId: series,
						},
						data: {
							drivingSlipFiles: [...newObj, ...(cachedRequest?.drivingSlipFiles ?? [])],
						},
					});

					const cachedRequestCaseFiles = cache.readQuery<GetCaseFiles, GetCaseFilesVariables>({
						query: GET_CASE_FILES,
						variables: {
							caseErpNo: caseNo,
							folder: caseNo,
						},
					});
					cache.writeQuery<GetCaseFiles, GetCaseFilesVariables>({
						query: GET_CASE_FILES,
						variables: {
							caseErpNo: caseNo,
							folder: caseNo,
						},
						data: {
							caseFiles: [...newObj, ...(cachedRequestCaseFiles?.caseFiles ?? [])],
						},
					});
				},
			});
		}
	};

	const onDownload = (file: GetDrivingSlipFiles_drivingSlipFiles) => {
		setFileToDownload({
			fileName: file.name,
			extension: file.extension,
			folder: series,
			downloadType: 'download',
		});
	};

	const onView = (file: GetDrivingSlipFiles_drivingSlipFiles) => {
		setFileToDownload({
			fileName: file.name,
			extension: file.extension,
			folder: series,
			downloadType: 'view',
		});
		presentDocumentViewer();
	};

	/* PHOTOS */

	const { photo, takePhoto } = useSinglePhotoCapture();
	const { photos, choosePhotos, resetPhotos } = usePhotoChooser();

	const uploadPhoto = async (photo: UserPhoto, comments?: string) => {
		// const fileAsBytes = stringToByteArray(photo.base64EncodedData);
		// const fileExtension = photo.format.startsWith('.') ? photo.format : `.${photo.format}`;
		// const filename = photo.filepath + fileExtension;
		// const fileMetadata = comments ? [{ key: FileMetadataField.Comments, value: comments }] : undefined;

		if (mobileImageComment) {
			//setFilesToUpload([{ fileData: fileAsBytes, filename, fileMetadata, blob: photo.webviewPath }]);
			presentAddCommentsToPhotoModal();
		} else {
			await uploadPhotos([photo]);
		}
	};
	const uploadPhotos = async (photos: UserPhoto[], comments?: string) => {
		const files = photos.map(photo => {
			const blob = photo.webviewPath;
			const fileAsBytes = stringToByteArray(photo.base64EncodedData);
			const filename = getFileName(photo);
			return { filename, fileData: fileAsBytes, blob };
		});

		await onPhotosUpload({ files, comments });
	};
	/* VIDEOS */

	const [presentVideoCaptureModal, dismissVideoCaptureModal] = useIonModal(VideoCaptureModal, {
		onSubmit: async (video: UserVideo, comments?: string) => {
			dismissVideoCaptureModal();
			const fileAsBytes = stringToByteArray(video.base64EncodedData);
			const filename = getPath(video.filepath);
			const fileMetadata = comments ? [{ key: FileMetadataField.Comments, value: comments }] : undefined;
			await onUpload([
				{
					filename: filename,
					fileData: fileAsBytes,
					fileMetadata: fileMetadata,
					blob: video.filepath,
				},
			]);
		},
		onDismiss: () => dismissVideoCaptureModal(),
	} as VideoCaptureModalProps);

	/* DOCUMENTS */
	const [showUploadInput, setShowUploadInput] = React.useState(false);
	const [showUploadImageInput, setShowUploadImageInput] = React.useState(false);

	const onFileInputUpload = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
		if (!e.target.files || e.target.files?.length === 0) {
			setShowUploadInput(false);
			setShowUploadImageInput(false);
			alert(t('error.fileMissing'));
			return;
		}
		const fileInputs: (FileInput & { blob: string })[] = [];
		for (let i = 0; i < e.target.files.length; i++) {
			const file = e.target.files[i];
			const fileAsBase64String = await toBase64(file);
			const fileAsBytes = stringToByteArray(fileAsBase64String);
			//const bytes = Array.from(new Uint8Array(await getByteArray(file)));
			fileInputs.push({ fileData: fileAsBytes, filename: file.name, blob: getObjectUrl(file) });
		}
		await onUpload(fileInputs);
		// setFilesToUpload(fileInputs);
		// setTimeout(() => {

		// 	filesToUploadRef.current = null;
		// }, 1000);
		setShowUploadInput(false);
		setShowUploadImageInput(false);
		// presentAddCommentsToFilesModal();
		//await onUpload(fileInputs);
	};

	const { uploadDrivingSlipFile, addFilesToCache } = useUploadDrivingSlipFilesRest();

	// TODO: Finished new file upload
	const onFileInputUploadREST = async (e: React.ChangeEvent<HTMLInputElement>, comments?: string): Promise<void> => {
		if (!e.target.files || e.target.files?.length === 0) {
			setShowUploadInput(false);
			setShowUploadImageInput(false);
			alert(t('error.fileMissing'));
			return;
		}

		for (let i = 0; i < e.target.files.length; i++) {
			const file = e.target.files[i];
			await uploadDrivingSlipFile(caseNo, series, [], file.name, file, i);
		}

		// setFilesToUpload(fileInputs);
		// setTimeout(() => {

		// 	filesToUploadRef.current = null;
		// }, 1000);
		setShowUploadInput(false);
		setShowUploadImageInput(false);
		// presentAddCommentsToFilesModal();
		//await onUpload(fileInputs);
	};

	const [presentAddCommentsToPhotosModal, dismissAddCommentsToPhotosModal] = useIonModal(AddCommentsToFilesModal, {
		addingFilesType: AddingFilesType.Photos,
		initialFiles: photos,
		onSubmit: async (files: (UserPhoto & { blob: string })[], comments?: string) => {
			dismissAddCommentsToPhotosModal();
			resetPhotos();
			await uploadPhotos(files, comments);
		},
		onDismiss: () => {
			dismissAddCommentsToPhotosModal();
			resetPhotos();
		},
	} as ChosenImagesModalProps);

	const [presentAddCommentsToPhotoModal, dismissAddCommentsToPhotoModal] = useIonModal(AddCommentsToFilesModal, {
		addingFilesType: AddingFilesType.Photos,
		initialFiles: [photo],
		onSubmit: async (files: (UserPhoto & { blob: string })[], comments?: string) => {
			dismissAddCommentsToPhotoModal();
			await uploadPhotos(files, comments);
		},
		onDismiss: () => dismissAddCommentsToPhotoModal(),
	} as ChosenImagesModalProps);

	const [presentAddCommentsToPhotoModalRest, dismissAddCommentsToPhotoModalRest] = useIonModal(AddCommentsToFilesModalREST, {
		addingFilesType: AddingFilesType.Photos,
		onSubmit: async (files: File[], comments?: string) => {
			const metaData = comments ? [{ key: FileMetadataField.Comments, value: comments }] : [];
			const newNames = await addFilesToCache(caseNo, series, metaData, files);
			dismissAddCommentsToPhotoModalRest();
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				const fileName = newNames[i];
				await uploadDrivingSlipFile(caseNo, series, metaData, fileName, file, i);
			}
		},
		onDismiss: () => dismissAddCommentsToPhotoModalRest(),
		type: { capture: 'environment', multiple: false },
	} as PropsRest);

	const [presentAddCommentsToPhotosModalRest, dismissAddCommentsToPhotosModalRest] = useIonModal(AddCommentsToFilesModalREST, {
		addingFilesType: AddingFilesType.Photos,
		onSubmit: async (files: File[], comments?: string) => {
			const metaData = comments ? [{ key: FileMetadataField.Comments, value: comments }] : [];
			const newNames = await addFilesToCache(caseNo, series, metaData, files);
			dismissAddCommentsToPhotosModalRest();
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				const fileName = newNames[i];
				await uploadDrivingSlipFile(caseNo, series, metaData, fileName, file, i);
			}
		},
		onDismiss: () => dismissAddCommentsToPhotosModalRest(),
		type: { capture: undefined, multiple: true },
	} as PropsRest);
	return (
		<>
			<BasePage title={t('common.files')} refreshFunc={refetchDsFiles} shouldOfflineWarn offlineMessage="reports.filesOffline">
				<IonFab vertical="top" horizontal="end" slot="fixed">
					<IonFabButton
						onClick={() => {
							setShowUploadInput(false);
							setShowUploadImageInput(false);
							presentAddFileMenu(
								[
									{
										text: t('common.takeSinglePicture'),
										handler: async () => {
											const result = await takePhoto({
												photoSource: CameraSource.Camera,
											});
											if (result.success && result.photo) {
												// Upload photo directly. Bypass the edit screen.
												await uploadPhoto(result.photo);
											}
										},
									},
									{
										text: t('common.takeSinglePicture') + ' ny',
										handler: async () => presentAddCommentsToPhotoModalRest(),
										cssClass: fileUploadMobileV2 ? '' : 'hidden',
									},
									{
										text: t('common.uploadMultiplePictures') + ' ny',
										handler: async () => presentAddCommentsToPhotosModalRest(),
										cssClass: fileUploadMobileV2 ? '' : 'hidden',
									},
									{
										text: t('common.uploadMultiplePictures'),
										handler: async () => {
											presentAddCommentsToPhotosModal();
											const result = await choosePhotos(true);
											if (!result.success) {
												dismissAddCommentsToPhotosModal();
											}
										},
									},
									{
										text: t('common.takeSingleVideo'),
										handler: async () => presentVideoCaptureModal(),
									},
									{
										text: t('common.uploadMultiplePicturesVideosDocuments'),
										//handler: () => inputFileRef.current?.click(),
										handler: () => setShowUploadInput(true),
									},
									{
										text: t('common.cancel'),
										role: 'cancel',
										cssClass: 'text-red',
									},
								],
								t('catalog.uploadFile'),
							);
						}}
					>
						<IonIcon icon={add} />
					</IonFabButton>
				</IonFab>

				<div className="pl-4">
					<p className="text-blue text-base font-semibold">{t('case.caseNo')}:</p>
					<p>{caseNo}</p>
				</div>

				{/* <input type="file" onChange={onFileInputUpload} ref={inputFileRef} className="hidden" multiple /> */}
				{loadingDsFiles && <DrivingSlipFilesListElementLoading />}

				<DrivingSlipFilesImageList
					files={fileGroups.images}
					loadingUploadFile={loadingUploadFile}
					loadingDeleteFile={loadingDeleteFile}
					loadingDownloadFile={dsFileToDownloadLoading}
					uploadingFileType={uploadingFileType}
					onDelete={onDelete}
					onDownload={onDownload}
				/>

				<DrivingSlipFilesVideoList
					files={fileGroups.videos}
					loadingUploadFile={loadingUploadFile}
					loadingDeleteFile={loadingDeleteFile}
					loadingDownloadFile={dsFileToDownloadLoading}
					uploadingFileType={uploadingFileType}
					onDelete={onDelete}
					onDownload={onDownload}
				/>

				<DrivingSlipFilesDocumentList
					files={fileGroups.documents}
					loadingUploadFile={loadingUploadFile}
					loadingDeleteFile={loadingDeleteFile}
					loadingDownloadFile={dsFileToDownloadLoading}
					uploadingFileType={uploadingFileType}
					onDelete={onDelete}
					onDownload={onDownload}
					onView={onView}
				/>
			</BasePage>
			{showUploadInput && <UploadInput onFileInputUpload={onFileInputUpload} />}
			{showUploadImageInput && <UploadImageInput onFileInputUpload={onFileInputUploadREST} />}
		</>
	);
};

interface UploadInputProps {
	onFileInputUpload: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
}

const UploadInput: React.FC<UploadInputProps> = ({ onFileInputUpload }) => {
	const inputFileRef = React.useRef<HTMLInputElement | null>(null);
	React.useEffect(() => {
		inputFileRef.current?.click();
		return () => {
			inputFileRef.current = null;
		};
	}, []);

	return (
		<>
			<input type="file" onChange={onFileInputUpload} ref={inputFileRef} className="hidden" multiple />
		</>
	);
};

const UploadImageInput: React.FC<UploadInputProps> = ({ onFileInputUpload }) => {
	const inputPhotoRef = React.useRef<HTMLInputElement | null>(null);
	React.useEffect(() => {
		inputPhotoRef.current?.click();
		return () => {
			inputPhotoRef.current = null;
		};
	}, []);

	return (
		<>
			<input type="file" accept="image/*" onChange={onFileInputUpload} ref={inputPhotoRef} className="hidden" />
		</>
	);
};
export default DrivingSlipFilesOverview;
