import React, { useContext } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { QueueEntry, QueueEntryStatus } from 'Helpers';
import { TIME_TO_WAIT_BETWEEN_ERROR_RETRIES_MS, TIME_TO_WAIT_BETWEEN_POLLING_QUEUE_MS } from './constants';
import { ApiMutationKey, ApiMutation, FailedApiMutation, ApiMutationQueueProcessorProps } from './types';
import { toQueuedMutationEvent } from './helper';
import {
	useCreateCaseAgreements,
	useUpdateCaseRiskEvaluationAnswers,
	useSetDrivingSlipDrivingStarted,
	useSetDrivingSlipFacadePictureTaken,
	useSetDrivingSlipQuestionnaireStatus,
	useSetDrivingSlipRiskEvaluationStatus,
	useSetDrivingSlipStatus,
	useSetDrivingSlipNoWasteUsed,
	useUploadDrivingSlipFile,
	useSetDrivingSlipQuestionnaire,
	useUpdateCase,
	useCreateCaseDraftItemUsage,
	useCreateInspectionReport,
	useCreateMoistureReport,
	useCreateMoistureTrygReport,
	useCreateIndoorClimateReport,
	useCreateFalckDehumidifierReport,
	useModifyDrivingSlipFileMetadata,
	useCreateInspectionMovablesReport,
} from 'Store';
import { MutationFunc } from 'Hooks/Apollo/Mutations/types';
import { isConnected } from 'NetworkContext';
import EnvironmentVariableContext from '@ssg/common/EnvironmentVariableContext';
import Button from '@ssg/common/Components/Button';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { postFile } from 'Hooks/useUploadDrivingSlipFilesRest';

type ApiMutations = {
	[key in ApiMutationKey]: MutationFunc<any, any>;
};

const fakeQueueEntry: ApiMutation = {
	key: ApiMutationKey.Case_CreateCaseAgreements,
	variables: {
		id: '',
		newCaseAgreements: [],
		trackTwoOptionSelected: false,
	},
};

const fakeFailedQueueEntry: FailedApiMutation = {
	error: 'EROROR',
	key: ApiMutationKey.Case_CreateCaseAgreements,
	variables: {
		id: '',
		newCaseAgreements: [],
		trackTwoOptionSelected: false,
	},
};

export const ApiMutationQueueProcessor: React.FC<ApiMutationQueueProcessorProps> = ({ mutationsQueue, failedMutationsQueue, events: { onStart, onSuccess, onError, onCompleteAll, queueUpdated } }) => {
	const [createCaseAgreements] = useCreateCaseAgreements();
	const [updateCase] = useUpdateCase();
	const [updateCaseRiskEvaluationAnswers] = useUpdateCaseRiskEvaluationAnswers();
	const [createCaseDraftItemUsage] = useCreateCaseDraftItemUsage();
	const [setDrivingSlipDrivingStarted] = useSetDrivingSlipDrivingStarted();
	const [setDrivingSlipFacadePictureTaken] = useSetDrivingSlipFacadePictureTaken();
	const [setDrivingSlipNoWasteUsed] = useSetDrivingSlipNoWasteUsed();
	const [setDrivingSlipQuestionnaire] = useSetDrivingSlipQuestionnaire();
	const [setDrivingSlipQuestionnaireStatus] = useSetDrivingSlipQuestionnaireStatus();
	const [setDrivingSlipRiskEvaluationStatus] = useSetDrivingSlipRiskEvaluationStatus();
	const [setDrivingSlipStatus] = useSetDrivingSlipStatus();
	const [uploadDrivingSlipFile] = useUploadDrivingSlipFile();
	const [modifyDrivingSlipFileMetadata] = useModifyDrivingSlipFileMetadata();

	//Reports
	const [createInspectionReport] = useCreateInspectionReport();
	const [createInspectionMovablesReport] = useCreateInspectionMovablesReport();
	const [createMoistureReport] = useCreateMoistureReport();
	const [createMoistureTrygReport] = useCreateMoistureTrygReport();
	const [createIndoorClimateReport] = useCreateIndoorClimateReport();
	const [createFalckDehumidifierReport] = useCreateFalckDehumidifierReport();

	const { environmentTag, baseApiUrl } = useContext(EnvironmentVariableContext);
	const apiMutations: ApiMutations = useMemo(
		() => ({
			[ApiMutationKey.Case_CreateCaseAgreements]: createCaseAgreements,
			[ApiMutationKey.Case_UpdateCase]: updateCase,
			[ApiMutationKey.Case_UpdateRiskEvaluationAnswers]: updateCaseRiskEvaluationAnswers,
			[ApiMutationKey.Case_CreateCaseDraftItemUsage]: createCaseDraftItemUsage,
			[ApiMutationKey.DrivingSlip_SetDrivingStarted]: setDrivingSlipDrivingStarted,
			[ApiMutationKey.DrivingSlip_SetFacadePictureTaken]: setDrivingSlipFacadePictureTaken,
			[ApiMutationKey.DrivingSlip_SetNoWasteUsed]: setDrivingSlipNoWasteUsed,
			[ApiMutationKey.DrivingSlip_SetQuestionnaire]: setDrivingSlipQuestionnaire,
			[ApiMutationKey.DrivingSlip_SetQuestionnaireStatus]: setDrivingSlipQuestionnaireStatus,
			[ApiMutationKey.DrivingSlip_SetRiskEvaluationStatus]: setDrivingSlipRiskEvaluationStatus,
			[ApiMutationKey.DrivingSlip_SetStatus]: setDrivingSlipStatus,
			[ApiMutationKey.DrivingSlip_UploadFile]: uploadDrivingSlipFile,
			[ApiMutationKey.DrivingSlip_ModifyDrivingSlipFileMetadata]: modifyDrivingSlipFileMetadata,

			// Reports
			[ApiMutationKey.DrivingSlip_CreateInspectionReport]: createInspectionReport,
			[ApiMutationKey.DrivingSlip_CreateInspectionMovablesReport]: createInspectionMovablesReport,
			[ApiMutationKey.DrivingSlip_CreateMoistureReport]: createMoistureReport,
			[ApiMutationKey.DrivingSlip_CreateMoistureTrygReport]: createMoistureTrygReport,
			[ApiMutationKey.DrivingSlip_CreateIndoorClimateReport]: createIndoorClimateReport,
			[ApiMutationKey.DrivingSlip_CreateFalckDehumidifierReport]: createFalckDehumidifierReport,

			// Rest
			[ApiMutationKey.REST_DrivingSlip_UploadFile]: undefined as unknown as MutationFunc<any, any>,
		}),
		[
			createCaseAgreements,
			updateCase,
			updateCaseRiskEvaluationAnswers,
			createCaseDraftItemUsage,
			setDrivingSlipDrivingStarted,
			setDrivingSlipFacadePictureTaken,
			setDrivingSlipNoWasteUsed,
			setDrivingSlipQuestionnaire,
			setDrivingSlipQuestionnaireStatus,
			setDrivingSlipRiskEvaluationStatus,
			setDrivingSlipStatus,
			uploadDrivingSlipFile,
			modifyDrivingSlipFileMetadata,
			// Reports
			createInspectionReport,
			createInspectionMovablesReport,
			createMoistureReport,
			createMoistureTrygReport,
			createIndoorClimateReport,
			createFalckDehumidifierReport,
		],
	);

	const pollingTimeoutRef = useRef<number>();
	const noPopQueue = useFlag(FeatureFlagEnums.NO_POP_QUEUE);

	const processMutation = useCallback(
		async (entry: QueueEntry<ApiMutation>) => {

			if (entry.data.key === ApiMutationKey.REST_DrivingSlip_UploadFile) {
				if (entry.data.variables) {
					return postFile(baseApiUrl, entry.data.variables);
				}
			}
			const dictEntry = apiMutations[entry.data.key];

			if (!dictEntry) {
				console.warn('No mutation has been specified for key: ' + entry.data.key);
				return new Promise(r => setTimeout(r, 1_000));
			}

			return dictEntry({ variables: entry.data.variables });
		},
		[apiMutations, baseApiUrl],
	);

	// async function updateCount(queueUpdated) {
	// 	const allEntriesCount = await mutationsQueue.getCount();
	// 	const allQueueEntries = await mutationsQueue.getAll();
	// 	const count = allQueueEntries.filter(entry => entry.metadata.status !== QueueEntryStatus.done).length;
	// 	queueUpdated(count);
	// }

	useEffect(() => {
		// Polling function that calls itself once it has completed, waiting 10 seconds between each invocation
		async function mutationsRetrier(): Promise<void> {
			if (noPopQueue) {
				const allEntriesCount = await mutationsQueue.getCount();
				const allQueueEntries = await mutationsQueue.getAll();
				queueUpdated(allQueueEntries.filter(entry => entry.metadata.status !== QueueEntryStatus.done).length);
				// Loop through the queue, taking the first entry and try process it
				for (let queueEntryPosition = 0; queueEntryPosition < allEntriesCount; ++queueEntryPosition) {
					const queueEntry1: QueueEntry<ApiMutation> | undefined = allQueueEntries[queueEntryPosition];

					if (typeof queueEntry1 === 'undefined') {
						continue;
					}
					if (queueEntry1.metadata.status === QueueEntryStatus.done) {
						continue;
					}

					if (!isConnected()) {
						pollingTimeoutRef.current = window.setTimeout(mutationsRetrier, TIME_TO_WAIT_BETWEEN_POLLING_QUEUE_MS);
						continue;
					}

					if (queueEntry1.metadata.status === QueueEntryStatus.sending) {
						const sendStartTime = queueEntry1.metadata.sendStartTime ?? new Date();
						const timeOutTime = sendStartTime.getTime() + (1000 * 60 * 5);
						if (timeOutTime < new Date().getTime()) {
							queueEntry1.metadata.status = QueueEntryStatus.waiting;
							await mutationsQueue.updateEntry(queueEntry1);
						} else {
							continue;
						}
					}

					if (queueEntry1.metadata.numberOfRetries > queueEntry1.metadata.maxNumberOfRetries) {
						queueEntry1.metadata.status = QueueEntryStatus.error;
						await mutationsQueue.updateEntry(queueEntry1);
						continue;
					}

					console.group(`ApiMutationQueueProcessor: Mutation: ${queueEntry1.data.key} (ID: ${queueEntry1.id})`);

					// Inform our listeners that we have started replaying a queued entry
					onStart(toQueuedMutationEvent(queueEntry1, queueEntryPosition), allEntriesCount, queueEntryPosition);

					try {
						queueEntry1.metadata.status = QueueEntryStatus.sending;
						queueEntry1.metadata.sendStartTime = new Date();
						await mutationsQueue.updateEntry(queueEntry1);

						await processMutation(queueEntry1);

						// Inform our listeners that we succeeded replaying a queued entry
						onSuccess(toQueuedMutationEvent(queueEntry1, queueEntryPosition));

						queueEntry1.metadata.status = QueueEntryStatus.done;
						await mutationsQueue.updateEntry(queueEntry1);
						queueUpdated(allQueueEntries.filter(entry => entry.metadata.status !== QueueEntryStatus.done).length);
					} catch (error: any) {
						queueEntry1.metadata.numberOfRetries = queueEntry1.metadata.numberOfRetries + 1;
						queueEntry1.metadata.lastError = error;
						queueEntry1.metadata.status = QueueEntryStatus.waiting;
						await mutationsQueue.updateEntry(queueEntry1);
						// Inform our listeners that an error occurred fetching our queued request
						onError(toQueuedMutationEvent(queueEntry1, queueEntryPosition, error));

						await new Promise(r => setTimeout(r, TIME_TO_WAIT_BETWEEN_ERROR_RETRIES_MS));
					}

					console.log('allEntriesCount: ', allEntriesCount, 'queueEntryPosition: ', queueEntryPosition, 'result:', allEntriesCount === queueEntryPosition);
					console.groupEnd();
				}
				const allQueueEntriesToCheck = await mutationsQueue.getAll();
				for (let queueEntryPosition = 0; queueEntryPosition < allQueueEntriesToCheck.length; ++queueEntryPosition) {
					const queueEntry: QueueEntry<ApiMutation> | undefined = allQueueEntriesToCheck[queueEntryPosition];

					if (typeof queueEntry?.id === 'undefined') {
						continue;
					}

					if (queueEntry.metadata.status === QueueEntryStatus.error) {
						const error = queueEntry.metadata.lastError;
						const data = { ...queueEntry.data, error };
						const failedApiMutation: QueueEntry<FailedApiMutation> = { ...queueEntry, data };
						failedMutationsQueue.pushEntry(failedApiMutation);
						mutationsQueue.deleteEntry(queueEntry.id);
					}

					if (queueEntry.metadata.status === QueueEntryStatus.done) {
						mutationsQueue.deleteEntry(queueEntry.id);
					}
				}

				queueUpdated(await mutationsQueue.getCount());

				if ((await mutationsQueue.getCount()) === 0) {
					onCompleteAll();
				}
				pollingTimeoutRef.current = window.setTimeout(mutationsRetrier, TIME_TO_WAIT_BETWEEN_POLLING_QUEUE_MS);
				return;
			}

			let connected = isConnected();

			// We only want to retry once we're back online.
			if (connected) {
				try {
					let queueEntry: QueueEntry<ApiMutation> | undefined;
					let allEntriesCount = await mutationsQueue.getCount();
					queueUpdated(allEntriesCount);

					let queueEntryPosition = 0;

					// Loop through the queue, taking the first entry and try process it
					while (connected && (queueEntry = await mutationsQueue.shiftEntry())) {
						if (typeof queueEntry === 'undefined') {
							continue;
						}

						console.group(`ApiMutationQueueProcessor: Mutation: ${queueEntry.data.key} (ID: ${queueEntry.id})`);

						// queueEntryPosition let's listeners know what number the entry is in the queue
						queueEntryPosition++;

						// Inform our listeners that we have started replaying a queued entry
						onStart(toQueuedMutationEvent(queueEntry, queueEntryPosition), allEntriesCount, queueEntryPosition);

						try {
							// console.log('Replaying mutation', queueEntry);

							// TODO: handle undefined
							await processMutation(queueEntry);

							// console.log('Successfully replayed mutation:', queueEntry);

							// Inform our listeners that we succeeded replaying a queued entry
							onSuccess(toQueuedMutationEvent(queueEntry, queueEntryPosition));
							queueUpdated(await mutationsQueue.getCount());
							// console.log(`Mutation '${queueEntry.data.key}' has been replayed in queue`);
						} catch (error: any) {
							console.error(error, queueEntry);

							// Inform our listeners that an error occurred fetching our queued request
							onError(toQueuedMutationEvent(queueEntry, queueEntryPosition, error));

							// Check the metadata of the queue entry for how many times it has been replayed
							// If it has not yet exceeded the limit (3) then re-add it to the queue for future fetching
							// Otherwise there's nothing we can do besides log the error
							const canRetry = queueEntry.metadata.numberOfRetries < queueEntry.metadata.maxNumberOfRetries;
							if (canRetry) {
								queueEntry.metadata.numberOfRetries++;
								// Delay before re-trying mutation
								await new Promise(r => setTimeout(r, TIME_TO_WAIT_BETWEEN_ERROR_RETRIES_MS));
								await mutationsQueue.unshiftEntry(queueEntry);
								const count = await mutationsQueue.getCount();
								queueUpdated(count);
								allEntriesCount = allEntriesCount++;
							} else {
								const data = { ...queueEntry.data, error };
								const failedApiMutation: QueueEntry<FailedApiMutation> = { ...queueEntry, data };

								// console.error('Exceeded number of retries', failedApiMutation);
								const count = await mutationsQueue.getCount();
								queueUpdated(count);
								await failedMutationsQueue.pushEntry(failedApiMutation);
							}
						}

						connected = isConnected();
						if (!connected) {
							// console.log('Connectivity was interuppted. Requeuing entry.', queueEntry);
							await mutationsQueue.pushEntry(queueEntry);
							const count = await mutationsQueue.getCount();
							queueUpdated(count);

							pollingTimeoutRef.current = window.setTimeout(mutationsRetrier, TIME_TO_WAIT_BETWEEN_POLLING_QUEUE_MS);
							return;
						}
						console.log('allEntriesCount: ', allEntriesCount, 'queueEntryPosition: ', queueEntryPosition, 'result:', allEntriesCount === queueEntryPosition);
						if (allEntriesCount === queueEntryPosition) {
							// console.log('All mutations in queue have been replayed; the queue is now empty!');
							onCompleteAll();
						}
						console.groupEnd();
					}
				} catch (error) {
					// This would unlikely occur if unable to unshift an entry off the queue
					console.error('We are connected but there was an error processing queue.', error);
					onCompleteAll();
				}
			} else {
				console.log("We are still offline, don't process queue.");
			}

			// Reset timeout after processing requests
			pollingTimeoutRef.current = window.setTimeout(mutationsRetrier, TIME_TO_WAIT_BETWEEN_POLLING_QUEUE_MS);
		}

		const setPollBasedOnConnectionStatus = (connected: boolean) => {
			if (connected) {
				window.clearTimeout(pollingTimeoutRef.current);
				mutationsRetrier();
			} else {
				window.clearTimeout(pollingTimeoutRef.current);
			}
		};
		navigator.connection?.addEventListener('change', () => {
			setPollBasedOnConnectionStatus(isConnected());
		});

		setPollBasedOnConnectionStatus(isConnected());

		// Listen to network changes and start/stop the poll accordingly
		// const listener = Network.addListener('networkStatusChange', setPollBasedOnConnectionStatus);

		// // Initial call to start the polling function if we're connected
		// Network.getStatus().then(setPollBasedOnConnectionStatus);

		// return () => {
		// 	listener?.remove();
		// };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (environmentTag !== 'DEV') return <></>;

	return (
		<div className="absolute bg-black z-50 w-full p-4 bottom-0 flex flex-row space-x-2 hidden">
			<Button
				text="Add to mutationsQueue"
				onClick={async () => {
					await mutationsQueue.pushEntry({
						data: fakeQueueEntry,
						metadata: {
							numberOfRetries: 1,
							maxNumberOfRetries: 3,
							status: QueueEntryStatus.waiting,
						},
					});
					const count = await mutationsQueue.getCount();
					queueUpdated(count);
				}}
				primary
			/>
			<Button
				text="Add to failedMutationsQueue"
				onClick={async () => {
					await failedMutationsQueue.pushEntry({
						data: fakeFailedQueueEntry,
						metadata: {
							numberOfRetries: 3,
							maxNumberOfRetries: 3,
							status: QueueEntryStatus.waiting,
						},
					});
					const count = await mutationsQueue.getCount();
					queueUpdated(count);
				}}
				primary
			/>
			<Button
				text="Get all mutationsQueue"
				onClick={async () => {
					const all = await mutationsQueue.getCount();
					console.log(all);
				}}
				primary
			/>
			<Button
				text="Get all failedmutationsQueue"
				onClick={async () => {
					const all = await failedMutationsQueue.getCount();
					console.log(all);
				}}
				primary
			/>
		</div>
	);
};
